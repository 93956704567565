import React, { useContext, useState, useEffect } from 'react';
import { ContextApi } from '../components/store';
import { gsap } from 'gsap';

const WorkItem = ({title, description, links, difficulty, background, animateOpen }) => {
  const { state, setState } = useContext(ContextApi);
  const [backgroundImage, setBackgroundImage] = useState(null);

  useEffect(() => {
    import(`../assets/work/${background}`).then((res) => {
      setBackgroundImage(res.default);
      const tl = gsap.timeline();

      tl
        .to('.item', 1, {
          delay: 1,
          opacity: 1,
        })
        // .to('.item-title', 0.4, {
        //   delay: -0.6,
        //   stagger: 0.1,
        //   x: 0,
        // });
    });
  }, []);

  const modalOpenHandler = () => {
    const newState = { ...state };
    newState.isActiveItem = true;
    newState.activeWorkItem = { title, description, links, difficulty, backgroundImage };
    setState(newState);
    animateOpen();
  };

  return (
    <div
      style={backgroundImage && { backgroundImage: `url(${backgroundImage})` }}
      className="item"
      onClick={!state.isActiveItem ? modalOpenHandler : undefined}
    >
      <div className="item-title">{title}</div>
    </div>
  );
};

export default WorkItem;
