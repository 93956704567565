import React, { useState, useMemo, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/all';

import Layout from './layout';
import Router from './router';
import Nav from './nav';
import { ContextApi } from './store';

function App({ location }) {
  const [state, setState] = useState({});
  const providerValue = useMemo(() => ({ state, setState }), [state, setState]);

  useEffect(() => {
    const tl = gsap.timeline();
    const rule = CSSRulePlugin.getRule('.main:before');

    const vh = window.innerHeight * 0.01;
    const vw= window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);

    if (location.pathname === '/' || location.pathname === '/home') {
      tl
        .to(rule, 0, {
          display: 'none',
          delay: 0,
        })
        .to('.main', 0, {
          delay: 0,
          boxShadow: 'none',
          border: 'none',
        })
        .to('.nav-container', 0, {
          backgroundImage: 'none',
        });
    }
  }, []);

  return (
    <ContextApi.Provider value={providerValue}>
      <Layout>
        <div className={`main main-${location.pathname.substr(1)}`}>
          <Router />
          <div className="nav-container">
            <Nav />
          </div>
        </div>
      </Layout>
    </ContextApi.Provider>
  );
}

export default withRouter(App);
