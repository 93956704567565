import React, { useState } from 'react';
import SocialIcons from '../components/socials';

const Contact = () => {
  const [status, setStatus] = useState('');

  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus('SUCCESS');
      } else {
        setStatus('ERROR');
      }
    };
    xhr.send(data);
  };

  return (
    <div className="contact page-content">
      <div className="page-header">
        <h2>Contact</h2>
      </div>
      <div className="page-body">
        <SocialIcons />
        <div className="info">
          <div className="span">
            Send an email via form, or copy my address from the input below.
          </div>
          <div className="email">
            info@andrzejlichon.ovh
          </div>
        </div>
        <form onSubmit={submitForm} method="post" action="https://formspree.io/xyynjzzd">
          <div className="form-group">
            <input type="text" name="name" required="required" />
            <label htmlFor="name" className="control-label">
              Imię*
            </label>
            <i className="bar"></i>
          </div>
          <div className="form-group">
            <input type="email" name="_replyto" id="replyto" required="required" />
            <label htmlFor="replyto" className="control-label">
              Email*
            </label>
            <i className="bar"></i>
          </div>
          <div className="form-group">
            <input type="number" name="phone" required="required" />
            <label htmlFor="phone" className="control-label">
              Telefon*
            </label>
            <i className="bar"></i>
          </div>
          <div className="form-group">
            <textarea name="message" required="required"></textarea>
            <label htmlFor="message" className="control-label">
              Wiadomość*
            </label>
            <i className="bar"></i>
          </div>
          <input type="text" name="_gotcha" style={{ display: 'none' }} />
          <div className="disclaimer">
            <span>* pole wymagane</span>
          </div>
          <div className="button-container">
            {status === '' && (
              <button type="submit" className="button">
                <span>Send</span>
              </button>
            )}
            {status === 'SUCCESS' && <p>Thanks!</p>}
            {status === 'ERROR' && <p>Ooops! There was an error.</p>}
          </div>
        </form>
      </div>
      <div className="page-footer">{/* <SocialIcons /> */}</div>
    </div>
  );
};

export default Contact;
