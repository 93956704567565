import React, { useContext, useEffect } from 'react';
import WorkItem from '../components/work-item';
import { ContextApi } from '../components/store';
import { gsap } from 'gsap';
import WorkItemModal from '../components/work-item-modal';

// import SocialIcons from '../components/socials';

const Work = () => {
  const { state, setState } = useContext(ContextApi);

  useEffect(() => {
    return () => {
      const newState = { ...state };
      newState.isActiveItem = false;
      newState.activeWorkItem = {};
      setState(newState);
    };
  }, []);

  useEffect(() => {
    if (state.isActiveItem === false) {
      animateClose();
    }
  }, [state.isActiveItem]);

  const animateOpen = () => {
    const tl = gsap.timeline();

    tl.to('.modal', 0, {
      css: { display: 'block', opacity: 0 },
    })
      .to('.modal', 0.6, {
        ease: 'power4.out',
        delay: 0.1,
        opacity: 1,
        width: 1000,
        height: 1000,
      })
      .from('.modal', 0.6, {
        backgroundColor: 'transparent',
        delay: -0.3,
        ease: 'power4.out',
      })
      .to('.modal-item', 0.6, {
        opacity: 1,
        delay: -0.6,
      });
  };

  const animateClose = () => {
    const tl = gsap.timeline();

    const updateState = () => {
      if (state.isActiveItem === true) {
        const newState = { ...state };
        newState.isActiveItem = false;
        newState.activeWorkItem = {};
        setState(newState);
      }
    };

    tl.to('.modal', 0.3, {
      ease: 'power4.out',
      opacity: 0,
      width: 0,
      height: 0,
    })
      .to('.modal', 0, {
        css: { display: 'none' },
        onComplete: updateState,
      })
      .to('.modal-item', 0, {
        opacity: 0,
      });
  };

  const date = [
    {
      title: 'Commercial Gatsby Website',
      description: ['React', 'Gatsby', 'sass', 'CI/CD'],
      links: { live: 'https://adolfadamek.pl/' },
      difficulty: 4,
      background: 'adamek.jpg',
    },
    {
      title: 'Geodesy App',
      description: ['React', 'jsx', 'sass', 'materialize', 'd3', 'Firebase'],
      links: { github: 'https://github.com/andrew362/ZwisyLin', live: 'https://zwisylin1.firebaseapp.com/' },
      difficulty: 5,
      background: 'pomiar-lin.jpg',
    },
    {
        title: 'React GSAP Menu',
        description: ['React', 'GSAP', 'sass', 'gh-pages'],
        links: { github: 'https://github.com/andrew362/react-menu', live: 'https://andrew362.github.io/react-menu/index.html' },
        difficulty: 3,
        background: 'menu.jpg',
    },
    {
      title: 'Messages Board',
      description: ['React', 'Node.js', 'MongoDB', 'Express', 'Redux', 'JWT'],
      links: { github: 'https://github.com/andrew362/message-board', live: 'https://app.andrzejlichon.ovh/web-communicator/' },
      difficulty: 5,
      background: 'messanger.jpg',
    },
    {
      title: 'CupWiz Selling Page',
      description: ['html5', 'css3', 'java script', 'sass', 'MDB', 'Gulp', 'Firebase'],
      links: { github: 'https://github.com/andrew362/CupWiz', live: 'https://cupwiz-db062.firebaseapp.com/' },
      difficulty: 3,
      background: 'cupwiz.jpeg',
    },
    {
      title: 'Weather App',
      description: ['html5', 'css3', 'java script ES6'],
      links: { github: 'https://github.com/andrew362/myWeatherApp', live: 'https://andrew362.github.io/myWeatherApp/' },
      difficulty: 2,
      background: 'weatherApp.jpeg',
    },
    {
      title: 'Construction Company Website',
      description: ['html5', 'css3', 'java script'],
      links: { github: 'https://github.com/andrew362/Budowa_domow_Jarzebak', live: 'https://andrew362.github.io/Budowa_domow_Jarzebak/' },
      difficulty: 3,
      background: 'jarzebak.jpg',
    },
    {
      title: 'Drone Company Website',
      description: ['html5', 'css3', 'java script', 'materialize'],
      links: { github: 'https://github.com/andrew362/Kelm-Website', live: 'https://andrew362.github.io/Kelm-Website/' },
      difficulty: 2,
      background: 'kelm.jpeg',
    },
    {
      title: 'Wordpress Theme',
      description: ['html5', 'css3', 'java script', 'php'],
      links: { github: 'https://github.com/andrew362/Simple', live: 'https://app.andrzejlichon.ovh/simple/' },
      difficulty: 3,
      background: 'simple.jpg',
    },
    {
      title: 'Photo Gallery',
      description: ['html5', 'css3', 'java script', 'Particles JS'],
      links: { github: 'https://github.com/andrew362/BeautifulGallery', live: 'https://andrew362.github.io/BeautifulGallery/' },
      difficulty: 2,
      background: 'gallery.jpg',
    },    
    {
      title: 'Shoping Cart',
      description: ['React', 'sass', 'Redux', 'Firebase'],
      links: { github: 'https://github.com/andrew362/ReactForm', live: 'https://reactcart7.web.app/cart' },
      difficulty: 3,
      background: 'reactForm.jpg',
    },
  ];

  return (
    <div className="work page-content">
      <div className="page-header">
        <h2>Work</h2>
      </div>
      <div className="page-body">
        <div className="page-body-grid">
          {date.map((element, idx) => (
            <WorkItem
              key={idx}
              animateOpen={animateOpen}
              title={element.title}
              description={element.description}
              links={element.links}
              difficulty={element.difficulty}
              background={element.background}
            />
          ))}
        </div>
        <div className="page-body-modal">
          <div className="modal">
            <div className="modal-item">
              {!!state.isActiveItem && (
                <WorkItemModal
                  title={state.activeWorkItem.title}
                  animateClose={animateClose}
                  description={state.activeWorkItem.description}
                  links={state.activeWorkItem.links}
                  difficulty={state.activeWorkItem.difficulty}
                  background={state.activeWorkItem.backgroundImage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="page-footer"><SocialIcons /></div> */}
    </div>
  );
};

export default Work;
