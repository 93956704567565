import React from 'react';
import Close from '../assets/icons/close.svg';
import {ReactComponent as Star} from '../assets/icons/star.svg';

const WorkItemModal = ({ title, description, links, difficulty, background, animateClose }) => {
  return (
    <div style={{ backgroundImage: `url(${background})` }} className="item">
      <div className="item-title">{title}</div>
      <div className="item-content">
        <div className="close-btn" onClick={() => animateClose()}>
          <img src={Close} alt="close-btn" />
        </div>
        <div className="item-description">
          {description.map((desc, idx) => (
            <span key={idx}>{desc}</span>
          ))}
        </div>
        <div className="item-links">
          {Object.entries(links).map((link, idx) => (
            <span key={idx}>
              <a href={link[1]} target="_blank" rel="noopener noreferrer">
                {link[0]}
              </a>
            </span>
          ))}
        </div>
        <div className="stars">
          {[...Array(5).keys()].map((_, idx) => {
            if (idx+1 <= difficulty) {
              return <Star key={idx} className='filled' />
            } else {
              return <Star key={idx} />;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default WorkItemModal;
