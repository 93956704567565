import React from 'react';

const BlockChart = ({ name, value, className }) => {
  return (
    <div className="block-chart">
      <span>{name}</span>
      <div style={{ width: `${value}%` }} className={`block-chart-line ${className}`}></div>
    </div>
  );
};

export default BlockChart;
