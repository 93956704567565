import React from 'react';
import BlockChart from '../components/block-chart';
import LineChart from '../components/line-chart';
import RadialChart from '../components/charts/radial';
// import SocialIcons from '../components/socials';

const skills_dev = [
  { name: 'html', value: 90 },
  { name: 'CSS', value: 80 },
  { name: 'Java script', value: 70 },
  { name: 'jQuery', value: 40 },
  { name: 'React', value: 70 },
  { name: 'Gatsby', value: 50 },
  { name: 'Wordpress', value: 30 },
  { name: 'GIT', value: 75 },
  { name: 'Bootstrap', value: 40 },
  { name: 'Sass', value: 80 },
  { name: 'Webpack', value: 35 },
  { name: 'Node', value: 50 },
  { name: 'PHP', value: 30 },
];

const skills_design = [
  { name: 'Photoshop', value: 85 },
  { name: 'Gimp', value: 60 },
  { name: 'Figma', value: 75 },
  { name: 'Adobe XD', value: 65 },
  { name: 'Inkscape', value: 60 },
  { name: 'UX/UI', value: 55 },
];

const skills_engeenering = [
  { name: 'Surveying', value: 95 },
  { name: 'Thermovision', value: 80 },
  { name: 'GIS', value: 70 },
  { name: 'CAD Mapping', value: 75 },
];

const gradientColors1 = ['rgba(183,153,204,.35)', 'rgba(94,174,253,.75)'];
const gradientColors2 = ['rgba(242,233,129,.35)', 'rgba(235,182,113,.75)'];
const gradientColors3 = [
  ['hsla(287deg,34%,67%,.35)', 'hsla(200deg,34%,67%,.35)', 'hsla(130deg,34%,67%,.35)'],
  'rgba(146,146,146,.85)',
];

const barChart1 = {
  type: 'bar',
  options: {
    indexAxis: 'y',
    scales: {
      x: {
        ticks: {
          callback: function (value, index, values) {
            return value + '%';
          },
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    elements: {
      bar: {
        barThickness: 2,
      },
    },
    animations: {
        borderWidth: {
          duration: 1000,
          easing: 'linear',
          from: 1,
          to: 3,
          loop: true,
        },
      },
  },
};

const barChart2 = {
  type: 'polarArea',
  options: {
    indexAxis: 'x',
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: true,
      },
    },
    scales: {
      r: {
        ticks: { showLabelBackdrop: false },
      },
    },
    animations: {
        borderWidth: {
          duration: 1000,
          easing: 'linear',
          from: 1,
          to: 3,
          loop: true,
        },
      },
  },
};

const Skills = () => {
  return (
    <div className="skills page-content">
      <div className="page-header">
        <h2>Skills & Experience</h2>
      </div>
      <div className="page-body">
        <span>Commercial experience</span>
        <div className="experience-chart">
          <LineChart />
        </div>
        <span>Approximate skills values</span>
        <div className="block-charts">
          <BlockChart className="developer" name="Dev Skills" value={60} />
          <BlockChart className="design" name="Design Skills" value={40} />
          <BlockChart className="engineering" name="Engineering Skills" value={60} />
        </div>
        <span>Dev skills</span>
        <div className="section-charts">
          <RadialChart id="1" data={skills_dev} colors={gradientColors1} />
        </div>
        <span style={{ marginBottom: '2rem', display: 'block' }}>Design skills</span>
        <div className="section-charts">
          <RadialChart id="2" data={skills_design} config={barChart1} colors={gradientColors2} />
        </div>
        <span style={{ marginBottom: '2rem', display: 'block' }}>Engineering skills</span>
        <div className="section-charts">
          <RadialChart id="3" data={skills_engeenering} config={barChart2} colors={gradientColors3} />
        </div>
      </div>
      <div className="page-footer">{/* <SocialIcons /> */}</div>
    </div>
  );
};

export default Skills;
