import React from 'react';
import SocialIcons from '../components/socials';

const Home = () => {
  return (
    <div className="home page-content">
      <div className="page-header">
        <h1>Hi! I'm Andrew. I'm a...</h1>
      </div>
      <div className="page-body">
        <p>
          <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Front-end_web_development">
            Front-end{' '}
          </a>
          <a
            target="_blank"
            className="deep-orange-text"
            rel="noopener noreferrer"
            href="https://en.wikipedia.org/wiki/Maniac"
          >
            maniac{' '}
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Design">
            Design{' '}
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="deep-orange-text"
            href="https://en.wikipedia.org/wiki/Lover"
          >
            lover{' '}
          </a>
          <br />
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="deep-orange-text"
            href="https://en.wikipedia.org/wiki/Freelancer"
          >
            freelance{' '}
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Creator">
            Creator{' '}
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="deep-orange-text"
            href="https://dictionary.cambridge.org/pl/dictionary/english/versatile"
          >
            versatile{' '}
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Engineer">
            Engineer
          </a>
        </p>
      </div>
      <div className="page-footer">
        <SocialIcons />
      </div>
    </div>
  );
};

export default Home;
