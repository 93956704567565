import React from 'react';

const days = Math.floor((new Date() - new Date('06.01.2019')) / 1000 / 60 / 60 / 24);

const LineChart = () => {
  return (
    <div className="line-chart">
      <svg width="100%" height="auto" viewBox="0 0 920 306" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7" y="1.28572" width="882" height="272.571" fill="url(#paint0_linear)" fillOpacity="0.5"/>
      <rect x="7" y="1.28572" width="882" height="272.571" fill="url(#paint1_linear)" fillOpacity="0.3"/>
      <path d="M26.381 15.4177V16.2857H19.703V6.34572H26.255V7.21372H20.683V10.7977H25.541V11.6237H20.683V15.4177H26.381ZM28.1643 6.34572L31.4263 10.6857L34.6883 6.34572H35.7663L31.9443 11.3857L35.6543 16.2857H34.5763L31.4263 12.0857L28.2763 16.2857H27.1703L30.8803 11.3857L27.0583 6.34572H28.1643ZM37.08 16.2857V6.34572H41.196C41.6253 6.34572 42.0173 6.43905 42.372 6.62572C42.736 6.80305 43.0487 7.04105 43.31 7.33972C43.5713 7.62905 43.7767 7.96039 43.926 8.33372C44.0753 8.70705 44.15 9.08505 44.15 9.46772C44.15 9.87839 44.08 10.2751 43.94 10.6577C43.8 11.0311 43.604 11.3624 43.352 11.6517C43.1 11.9411 42.7967 12.1744 42.442 12.3517C42.0967 12.5291 41.7093 12.6177 41.28 12.6177H38.06V16.2857H37.08ZM38.06 11.7497H41.238C41.5273 11.7497 41.7887 11.6891 42.022 11.5677C42.2647 11.4371 42.47 11.2691 42.638 11.0637C42.806 10.8491 42.9367 10.6064 43.03 10.3357C43.1233 10.0557 43.17 9.76639 43.17 9.46772C43.17 9.15972 43.114 8.87039 43.002 8.59972C42.8993 8.31972 42.7547 8.07705 42.568 7.87172C42.3907 7.66639 42.176 7.50772 41.924 7.39572C41.6813 7.27439 41.4247 7.21372 41.154 7.21372H38.06V11.7497Z" fill="black" fillOpacity="0.7"/>
      <path d="M864.389 291.357H860.847V300.429H859.867V291.357H856.325V290.489H864.389V291.357ZM865.878 300.429V290.489H866.858V300.429H865.878ZM878.156 300.429V292.267L874.53 298.581H873.956L870.33 292.267V300.429H869.35V290.489H870.358L874.236 297.293L878.142 290.489H879.136V300.429H878.156ZM888.306 299.561V300.429H881.628V290.489H888.18V291.357H882.608V294.941H887.466V295.767H882.608V299.561H888.306Z" fill="black" fillOpacity="0.7"/>
      <path className='chartLine' d="M10 268.714L43 264.857L55.5 259.714L67 262.286L84 254.571L106 257.143L117 249.429H132L155.5 238.5H178L224 231.429L250 235.286L276.5 231.429L315.5 227.571L338 231.429L363.5 216L388.5 203.786L408.5 207.643H436L453 199.286H506L532 184.5H567L590 179.357L622 169.071L638.5 154.929L674.5 165.857L689.5 154.929C691 152.571 699.5 147.857 721.5 147.857C743.5 147.857 758.667 147.857 763.5 147.857L781.5 132.429C791.667 130.286 813.4 126 819 126C824.6 126 824 122.786 843.5 122.786L858 113.786L870 111.857L878.5 104.143L888.5 100.286" stroke="url(#paint2_linear)" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M896.968 263.73L918.344 272.73L918.217 273.23L918.376 273.857L897 282.857L896.753 281.888L915.642 273.935L7 273.857L7.00005 272.857L916.284 272.935L896.721 264.699L896.968 263.73Z" fill="#666666"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 27.4839L7 0L7.48747 0.205238L7.96082 0.00594515L14.9608 27.4899L14.0392 27.8779L8 4.16643V273.857H7V4.00666L0.921645 27.8719L0 27.4839Z" fill="#666666"/>
      <line x1="889" y1="100.536" x2="8" y2="100.536" stroke="black" strokeOpacity="0.5" strokeWidth="0.5" strokeDasharray="5 2"/>
      <line x1="889.25" y1="100.286" x2="889.25" y2="272.571" stroke="black" strokeOpacity="0.51" strokeWidth="0.5" strokeDasharray="5 2"/>
      <circle cx="888.5" cy="100.5" r="3.5" fill="#9553ED"/>
      <text className="daysCounter" x="460" y="306" textAnchor="middle" fill="#252a2e">{days}days</text>
      <defs>
      <linearGradient id="paint0_linear" x1="448" y1="1.28572" x2="448" y2="273.857" gradientUnits="userSpaceOnUse">
      <stop stopColor="#F2FFCE" stopOpacity="0"/>
      <stop offset="0.489583" stopColor="#BDE4F4" stopOpacity="0.25"/>
      <stop offset="0.84375" stopColor="#C7D3DA" stopOpacity="0.71"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="448" y1="1.28572" x2="448" y2="273.857" gradientUnits="userSpaceOnUse">
      <stop offset="0.114583" stopColor="#D162E3" stopOpacity="0"/>
      <stop offset="0.348564" stopColor="#E6A3F1" stopOpacity="0.19"/>
      <stop offset="0.619222" stopColor="#E6A9F0" stopOpacity="0.212366"/>
      <stop offset="1" stopColor="white" stopOpacity="0.35"/>
      </linearGradient>
      <linearGradient id="paint2_linear" x1="889" y1="100.286" x2="2.38937" y2="203.057" gradientUnits="userSpaceOnUse">
      <stop stopColor="#9651ED"/>
      <stop offset="1" stopColor="#247BFE" stopOpacity="0.39"/>
      </linearGradient>
      </defs>
      </svg>
    </div>
  );
};

export default LineChart;
