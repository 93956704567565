import React, { useContext, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { gsap } from 'gsap';
import { ContextApi } from '../components/store';
import introBackground from '../assets/bg-focus-intro.jpg';

const Layout = ({ children, location, history }) => {
  const { state, setState } = useContext(ContextApi);
  const intro = useRef(null);



  useEffect(() => {
    const tl = gsap.timeline();

    const goToHomePage = () => {
      history.push('/home');
    }

    window.addEventListener('load', () => {

      if (location.pathname === '/') {
        tl
          .to('#loader', 0.6, {
            opacity: 0,
            delay: 0.6
          })
          .to('.view', 0.6, {
            opacity: 1,
            delay: -0.2,
          })
          .to(intro.current, 1, {
            ease: 'power4.in',
            opacity: 0,
            delay: 0.5,
          })
          .to('.nav-container', 0.3, {
            opacity: 1,
            onComplete: goToHomePage,
          })
          .to('#loader', 0, {
            display: 'none',
            delay: 0.5,
          });

      } else {
        tl
          .to('#loader', 1, {
            opacity: 0,
            delay: 0
          })
          .to('.view', 1, {
            opacity: 1,
            delay: -0.4,
          })
          .to('.nav-container', 0.3, {
            opacity: 1,
          })
          .to('#loader', 0, {
            display: 'none'
          });
      }
    })

  }, []);

  const clickHandler = (e) => {
    let modal = e.target;

    const checkIsModal = (modal) => {
      while (modal.className !== 'modal' && modal.className !== 'close-btn' && modal.tagName !== 'BODY') {
        modal = modal.parentNode;
        if (modal.className === 'modal') {
          return true;
        }
      }
    };

    if (!checkIsModal(modal)) {
      const newState = { ...state };
      newState.isActiveItem = false;
      newState.activeWorkItem = {};
      setState(newState);
    }
  };

  return (
    <>
      <div onClick={state.isActiveItem ? clickHandler : undefined} className="view">
        <div ref={intro} style={location.pathname === '/' ? { backgroundImage: `url(${introBackground})` } : null} className="intro-background"></div>
        {children}
      </div>
    </>
  );
};

export default withRouter(Layout);
