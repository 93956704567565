import React from 'react';
// import SocialIcons from '../components/socials';

const About = () => {
  
  return (
        <div className="about page-content">
          <div className="page-header">
            <h2>About</h2>
          </div>
          <div className="page-body">
            <p>
              Hello! My name is Andrzej and I have always wanted to be Web Developer
              and this is beginning of my journey into programming. With my
              strong willingness and hard work, I believe that my future is just
              right ahead. Every day I am learning somethig new and diving deeper into the World of Code,
              but the time has come to take next step and more challenges.
              I hope you will find here something interesting, I encourage you also to contact with me.
            </p>
          </div>
          <div className="page-footer">
              {/* <SocialIcons /> */}
          </div>
        </div>
  );
};

export default About;
