import React from 'react';
import { Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/all';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import Home from '../pages/home';
import About from '../pages/about';
import Work from '../pages/work';
import Skills from '../pages/skills';
import Contact from '../pages/contact';

gsap.registerPlugin(CSSRulePlugin);

const Router = () => {
  const routes = [
    { path: '/home', name: 'Home', Component: Home },
    { path: '/about', name: 'About', Component: About },
    { path: '/work', name: 'work', Component: Work },
    { path: '/skills', name: 'skills', Component: Skills },
    { path: '/contact', name: 'contact', Component: Contact },
  ];

  //opening
  const onEnter = (node) => {
    const tl = gsap.timeline();
    const rule = CSSRulePlugin.getRule('.main:before');

    if (node.className.indexOf('home') !== -1) {
      tl.to(rule, 0, {
        display: 'none',
        delay: 0.8,
      })
        .to('.main', 0.6, {
          ease: 'power4.out',
          delay: 0.2,
          height: 1000,
        })
        .to('.nav-container', 0.2, {
          opacity: 1,
          backgroundImage: 'none',
          delay: -0.2,
        })
        .to('.main', 0, {
          delay: -0.8,
          boxShadow: 'none',
          border: 'none',
        });
    } else {
      tl.to('.main', 0.6, {
        ease: 'power4.out',
        delay: 0.8,
        height: 1000,
      }).to('.nav-container', 0.2, {
        opacity: 1,
        delay: -0.2,
        backgroundImage: 'linear-gradient(180deg, rgba(96, 125, 139, 0.1) 0%, rgba(96, 125, 139, 0.5) 100%)',
      });
    }
  };

  //closing
  const onExit = (node) => {
    const tl = gsap.timeline();
    const rule = CSSRulePlugin.getRule('.main:before');
    
    if (node.className.indexOf('home') !== -1) {
      tl.to('.nav-container', 0.2, {
        opacity: 0,
      })
        .to('.main', 0.6, {
          ease: 'power4.out',
          height: 0,
          delay: 0.2,
        })
        .to('.main', 0, {
          delay: 0,
          boxShadow: '0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12)',
          border: '1px solid #fff',
        })
        .to(rule, 0, {
          display: 'block',
          delay: -0.2,
        });
    } else {
      tl.to('.nav-container', 0.2, {
        opacity: 0,
      }).to('.main', 0.6, {
        ease: 'power4.out',
        height: 0,
        delay: 0.2,
      });
    }
  };

  return (
    <>
      {routes.map(({ path, Component }) => (
        <Route key={path} exact path={path}>
          {({ match }) => (
            <CSSTransition
              in={match != null}
              timeout={1200}
              classNames="page"
              onExit={onExit}
              onEntering={onEnter}
              unmountOnExit
            >
              <SimpleBar className={`page ${path.substr(1)}`} forceVisible="y" autoHide={false}>
                <Component />
              </SimpleBar>
            </CSSTransition>
          )}
        </Route>
      ))}
    </>
  );
};

export default Router;
