import React from 'react';
import {ReactComponent as FB} from '../assets/icons/fb.svg'; 
import {ReactComponent as LI} from '../assets/icons/li.svg'; 
import {ReactComponent as GH} from '../assets/icons/gh.svg'; 
import {ReactComponent as GALLERY} from '../assets/icons/g.svg'; 

const SocialIcons = () => {
  return (
    <div className="modal-footer flex-center">
      <div className="socials">
        <a
          href="https://www.facebook.com/andrzej.lichon.3"
          target="_blank"
          rel="noopener noreferrer"
          className="social"
        >
          <FB />
        </a>
        <a
          href="https://www.linkedin.com/in/andrzejlichon"
          target="_blank"
          rel="noopener noreferrer"
          className="social"
        >
          <LI />
        </a>
        <a
          href="https://github.com/andrew362"
          target="_blank"
          rel="noopener noreferrer"
          className="social"
        >
          <GH />
        </a>
        <a
          href="https://andrzejlichon.gitlab.io/gallery/"
          target="_blank"
          rel="noopener noreferrer"
          className="social"
        >
          <GALLERY />
        </a>
      </div>
    </div>
  );
};

export default SocialIcons;
