import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';

const RadialChart = ({ id, data, config, colors }) => {
  const defaultConfig = {
    type: 'radar',
    data: {
      labels: [...data.map((el) => el.name)],
      datasets: [
        {
          data: [...data.map((el) => el.value)],
          borderWidth: 1,
          backgroundColor: colors[0],
          borderColor: colors[1],
          pointBackgroundColor: 'rgb(255, 99, 132)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(255, 99, 132)',
          pointRadius: 3,
          maxBarThickness: 30,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        r: {
          angleLines: {
            display: true,
          },
          suggestedMin: 0,
          suggestedMax: 100,
          animate: true,
          count: 0,
          ticks: {
            display: true,
            showLabelBackdrop: false,
          },
          max: 100,
          title: {
            display: false,
          },
        },
      },
      animations: {
        borderWidth: {
          duration: 1000,
          easing: 'linear',
          from: 1,
          to: 3,
          loop: true,
        },
      },
    },
  };

  useEffect(() => {
    const conf = typeof config === 'object' ? Object.assign(defaultConfig, config) : defaultConfig;
    const ctx = document.getElementById(`chart-${id}`);
    const myChart = new Chart(ctx, conf);
    
    return (()=>{
        myChart.destroy()
    })
  }, []);
  return (
    <div>
      <canvas id={`chart-${id}`} width="400" height="400"></canvas>
    </div>
  );
};

export default RadialChart;
